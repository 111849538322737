(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("THREE"), require("ConvexGeometry"));
	else if(typeof define === 'function' && define.amd)
		define(["THREE", "ConvexGeometry"], factory);
	else if(typeof exports === 'object')
		exports["ENABLE3D"] = factory(require("THREE"), require("ConvexGeometry"));
	else
		root["ENABLE3D"] = factory(root["THREE"], root["ConvexGeometry"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__428__, __WEBPACK_EXTERNAL_MODULE__152__) {
return 